.contenedor_menu{
    width: 100%;
    height: 50px;
    background-color: #652F6C;
    position: fixed;
    bottom: 0;
    z-index: 999;
    @media (min-width:1600px) {
        height: 60px;
    }
    &.ocultar{
        @media (max-width:1024px) {
            display: none;
        }
    }
    &_lista{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        ul{
            width: 90%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            li{
                list-style: none;
                display: flex;
                align-items: center;
                &:hover{
                    .span-left, .span-right{
                        opacity: 1;
                    }
                }
                .span-left{
                    opacity: 0;
                    width: 20px;
                    height: 23px;
                    background-image: url(../../assets/svg-left.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
                .span-right{
                    opacity: 0;
                    width: 20px;
                    height: 23px;
                    background-image: url(../../assets/svg-right.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }
                a{
                    margin: 0 5px;
                    text-decoration: none;
                    color: white;
                    font-family: "minion-regular";
                    font-size: 17px;
                    @media (min-width:1600px) {
                        font-size: 19px;
                    }
                }
            }
        }
    }
}

.menu_movil{
    display: none;
    width: 100%;
    height: 60px;
    background-color: #652F6C;
    position: fixed;
    z-index: 99;
    bottom: 0;
    align-items: center;
    @media screen and (orientation: landscape) {
        height: 50px;
    }
    &.mostrar{
        @media (max-width:1024px) {
            display: flex;
        }
    }
    .icon_telefono{
        position: fixed;
        z-index: 99;
        left: 20px;
    }
    ul{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        li{
            list-style: none;
            display: flex;
            align-items: center;
            color: white;
            font-family: "minion-regular";
            font-size: 17px;
            &:hover{
                .span-left, .span-right{
                    opacity: 1;
                }
            }
            .span-left{
                opacity: 0;
                width: 20px;
                height: 23px;
                background-image: url(../../assets/svg-left.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            .span-right{
                opacity: 0;
                width: 20px;
                height: 23px;
                background-image: url(../../assets/svg-right.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
    }
}