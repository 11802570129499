.contenedor_prototipos{
    width: 100%;
    height: 100vh;
    @media (max-width: 550px) {
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: auto;
        max-height: 700px;
    }
    .contenedor_grid{
        background-color: #FDB71A;
        grid-template-rows: 100vh;
        @media (max-width:1024px) {
            grid-template-rows: 50% 50%;
        }
        @media (max-width:550px) {
            grid-template-rows: 60% 40%;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            grid-template-rows: 350px 350px;
        }
    }
    .left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .card{
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            @media (min-width:1600px) {
                height: 70%;
            }
            @media (max-width: 550px) {
                height: 95%;
            }
            img{
                width: 40%;
                @media (min-width:1600px) {
                    width: 30%;
                }
                @media (max-width: 550px) {
                    width: 60%;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    width: 30%;
                }
            }
            .texto{
                width: 50%;
                @media (max-width: 550px) {
                    width: 70%;
                }
                p{
                    color: white;
                    text-align: center;
                }
            }
            .button_descarga{
                width: 250px;
                height: 35px;
                border: 1px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @media (max-width: 550px) {
                    width: 150px;
                }
                &:hover{
                    background-color: #652F6C;
                    a{
                        color: white;
                    }
                }
                a{
                    text-decoration: none;
                    cursor: pointer;
                    color: white;
                    font-family: "minion-regular";
                    font-size: 23px;
                    line-height: 20px;
                    padding-top: 5px;
                    @media (max-width: 550px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .right{
        width: 100%;
        height: 100%;
        .imagen_right{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}