.contenedor_brochure{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.548);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .closed{
        color: white;
        font-size: 30px;
        position: absolute;
        right: 5%;
        top: 5%;
        cursor: pointer;
    }
    .modal{
        width: 50%;
        height: 80%;
        background-color: #652F6C;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (min-width:1600px) {
            height: 60%;
        }
        @media (max-width:1024px) {
            width: 90%;
            height: 60%;
        }
        @media (max-width:  550px) {
            width: 95%;
            height: 70%;
            min-height: 630px;
        }
        img{
            width: 170px;
            @media (min-width:1600px) {
                width: 200px;
            }
        }
        p{
            margin-top: 30px;
            color: white;
        }
        form{
            width: 70%;
            height: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin-top: 30px;
            @media (min-width:1600px) {
                width: 50%;
                height: 50%;
            }
            @media (max-width:  550px) {
                width: 80%;
                height: 65%;
                justify-content: center;
            }
            div{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width:  550px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                input{
                    width: 46%;
                    height: 40px;
                    outline: none;
                    padding-left: 10px;
                    @media (max-width:  550px) {
                        width: 95%;
                        margin-bottom: 15px;
                    }
                    &::placeholder{
                        padding-left: 10px;
                        color: #ABABAB;
                    }
                }
                input[type="email"]{
                    width: 100%;
                }
                input[type="button"]{
                    background-color: transparent;
                    border: 1px solid white;
                    width: 150px;
                    height: 40px;
                    color: white;
                    cursor: pointer;
                    @media (max-width:  550px) {
                        margin-top: 15px;
                    }
                    &:hover{
                        background-color: #FDB71A;
                    }
                }
            }
            p{
                font-size: 12px;
                text-align: center;
            }
        }
    }
}