.contenedor_stiky{
    width: 45px;
    height: 60px;
    position: fixed;
    right: 7px;
    top: 45%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
       height: 130px;
    }
    @media (max-width: 550px) {
        height: 90px;
        right: 0;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 85px;
        width: 40px;
    }
    .whatsapp{
        width: 60px;
        height: 60px;
        background-color: #00BB2D;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 550px) {
            width: 40px;
            height: 40px;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            width: 40px;
            height: 40px;
        }
        img{
            @media (max-width: 550px) {
                width: 35px;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                width: 35px;
            }
        }
    }
    .brochure{
        display: none;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        background-color: #652F6C;
        @media (max-width: 1024px) {
            display: flex;
        }
        @media (max-width:550px) {
            width: 40px;
            height: 40px;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            width: 40px;
            height: 40px;
        }
        img{
            @media (max-width: 990px) and (orientation: landscape) {
                width: 25px;
            }
        }
    }
}