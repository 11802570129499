.swiper{
    max-width: 900px;
    width: 85%;
    height: 95%;
    display: flex;
    align-items: center;
    @media (min-width:1600px) {
        width: 80%;
        height: 80%;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 100%;
        width: 65%;
    }
}
.swiper-pagination{
    text-align: left !important;
    color: #652F6C;
    width: 50% !important;
    // &::after{
    //     content: "albercas";
    // }
}
.swiper-pagination-fraction {
    bottom: 5% !important;
    font-size: 20px !important;
    @media (max-width: 550px) {
        bottom: 0% !important;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        bottom: 10px !important;
    }
}
.swiper-button-prev{
    right: 5% !important;
    left: auto !important;
    top: auto !important;
    bottom: 5% !important;
    @media (max-width: 550px) {
        right: 8% !important;
        bottom: 0% !important;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        bottom: 10px !important;
    }
    &::after{
        font-size: 20px !important;
        color: #652F6C;

    }
}
.swiper-button-next{
    top: auto !important;
    bottom: 5% !important;
    width: 30px;
    @media (max-width: 550px) {
        bottom: 0% !important;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        bottom: 10px !important;
    }
    &::after{
        font-size: 20px !important;
        color: #652F6C;
    }
}
.swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
}
.contenedor_amenidades{
    width: 100%;
    height: 100vh;
    @media (max-width:550px) {
        padding: 20px 0;
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: auto;
    }
    .contenedor_grid{
        background-color: white;
        grid-template-columns: 50% 50%;
        @media (max-width:1024px) {
            grid-template-columns: 100%;
            grid-template-rows: 50% 50%;
        }
        @media (max-width:550px) {
            grid-template-rows: 60% 40%;
         }
        .left{
            width: 100%;
            height: 100%;
            grid-column: 1/2;
            display: flex;
            align-items: center;
            position: relative;
            @media (max-width:1024px) {
                grid-row: 2/3;
            }
            .carousel__slider{
                width: 100%;
                @media (max-width:1024px) {
                   height: 80%;
                }
                img{
                    width: 100%;
                    @media (max-width:1024px) {
                        width: 100%;
                        height: 95%;
                        object-fit: cover;
                    }
                }
            }
            .pagination_name{
                position: absolute;
                bottom: 5%;
                left: 5%;
                font-size: 20px;
                color: #652F6C;
                @media (max-width:550px) {
                    bottom: 0% !important;
                    left: 11%;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    bottom: 10px !important;
                    left: 6%;
                }
            }
        }
        .right{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            grid-column: 2/3;
            @media (max-width:1024px) {
                grid-column: 1/2;
                grid-row: 1/2;
            }
            .card{
                height: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                @media (min-width:1600px) {
                    height: 70%;
                }
                @media (max-width: 550px) {
                    height: 95%;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    height: 95%;
                }
                img{
                    width: 40%;
                    @media (min-width:1600px) {
                        width: 30%;
                    }
                    @media (max-width: 550px) {
                        width: 60%;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        width: 30%;
                    }
                }
                .texto{
                    width: 50%;
                    @media (max-width: 550px) {
                        width: 70%;
                    }
                    p{
                        color: #652F6C;
                        text-align: center;
                    }
                }
                .button_descarga{
                    width: 250px;
                    height: 35px;
                    border: 1px solid #652F6C;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    @media (max-width: 550px) {
                        width: 150px;
                    }
                    &:hover{
                        background-color: #FDB71A;
                        a{
                            color: #652F6C;
                        }
                    }
                    a{
                        text-decoration: none;
                        cursor: pointer;
                        color: #652F6C;
                        font-family: "minion-regular";
                        font-size: 23px;
                        line-height: 20px;
                        padding-top: 5px;
                        @media (max-width: 550px) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}