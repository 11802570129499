.contenedor_ubicacion{
    width: 100%;
    height: 100vh;
    @media (max-width: 550px) {
        height: auto;
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 1000px;
    }
    &_card{
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #652F6C;
        @media (max-width: 1024px) {
            flex-direction: column-reverse;
        }
        .mark{
            background: none !important;
        }
        .left{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            @media (max-width: 1024px) {
                width: 100%;
                height: 100%;
            }
            @media (max-width: 550px) {
                height: 100%;
            }
            .card{
                width: 100%;
                height: 100%;
            }
        }
        .MapBoxContainer .leaflet-tile-pane{
            filter: none;
        }
        .mapa{
            width: 100%;
            height: 100% !important;
        }
        .mapbox{
            width: 100%;
            height: 100%;
            display: flex;
            @media(max-width:550px){
                flex-direction: column-reverse;
            }
        }
        .styles{
            display: flex;
            margin-bottom: 15px;
        }
        .right{
            width: 50%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #FDB71A;
            @media (max-width: 1024px) {
                width: 100%;
                height: 50%;
            }
            @media (max-width: 550px) {
                height: auto;
                padding-top: 30px;
                justify-content: start;
            }
            .titulo_ubicacion{
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 60%;
                    @media (min-width:1600px) {
                        width: 75%;
                    }
                }
            }
            .direccion_ubicacion{
                width: 55%;
                height: 10%;
                @media (max-width: 550px) {
                    width: 90%;
                }
                p{
                    color: white;
                    text-align: center;
                }
            }
            .routeColor{
                color: #652F6C;
            }
            .iconos_ubicacion{
                height: 10%;
                width: 70%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                @media (min-width:1600px) {
                    width: 60%;
                }
                @media (max-width: 550px) {
                   align-items: center;
                   width: 80%;
                   padding-top: 20px;
                   padding-bottom: 20px;;
                }
                a{
                    &:nth-child(2){
                        img{
                            @media (min-width:1600px) {
                                width: 150px;
                            }
                            @media (max-width: 550px) {
                                width: 110px;
                            }
                        }
                    }
                }
                img{
                    width: 150px;
                    @media (min-width:1600px) {
                        width: 200px;
                    }
                    @media (max-width: 550px) {
                        width: 150px;
                    }
                }
            }
            .lista_ubicacion{
                width: 80%;
                height: 40%;
                display: grid;
                margin-left: 80px;
                @media (min-width:1600px) {
                    width: 100%;
                    justify-content: center;
                }
                @media (max-width: 550px) {
                    width: 95%;
                    height: auto;
                    margin-left: 0;
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
                .row_ubicacion{
                    width: 100%;
                    cursor: pointer;
                    p{
                        color: white;
                        @media (max-width: 550px) {
                           font-size: 14px;
                           text-align: left;
                        }
                        &:nth-child(1){
                            color: #FDB71A;
                            background-color: white;
                            width: 25px;
                            height: 25px;
                            min-width: 25px;
                            min-height: 25px;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 28px;
                            margin-right: 20px;
                            @media (max-width: 550px) {
                                margin-right: 10px;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .name:hover{
                    color: #652F6C;
                }
                .row_ubicacion_styles{
                    column-count: 2;
                    width: 100%;
                    @media(max-width:320px){
                        column-count: 1;
                    }
                }
            }
        }
    }
}