.contenedor_galeria{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 550px) {
        height: auto
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 900px;
    }
    .titulo_galeria{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20%;
        @media (max-width: 550px) {
            height: 10%;
            margin: 30px 0;
        }
        h1{
            font-size: 60px;
            color: #652F6C;
            font-family: "minion-bold";
            @media (max-width: 550px) {
                font-size: 40px;
            }
        }
    }
    .grid_galeria{
        width: 90%;
        height: 70%;
        display: grid;
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-rows: 50% 50%;
        column-gap: 30px;
        row-gap: 30px;
        padding-bottom: 50px;
        @media (min-width:1600px) {
            width: 80%;
        }
        @media (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 25% 25% 25% 25%;
        }
        @media (max-width: 550px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            column-gap: 20px;
            row-gap: 20px;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            column-gap: 10px;
            row-gap: 10px;
         }
        div{
            width: 100%;
            height: 100%;
            object-fit: cover;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}