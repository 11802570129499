.contenedor_sanmiguel{
    width: 100%;
    height: 100vh;
    @media (max-width: 550px) {
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 700px;
        max-height: 700px;
    }
    &_card{
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #652F6C;
        @media (max-width: 1024px) {
            flex-direction: column-reverse;
        }
        .left{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            @media (max-width: 1024px) {
                width: 100%;
                height: 50%;
            }
            @media (max-width: 550px) {
                height: 60%;
            }
            @media (max-width: 990px) and (orientation: landscape) {
               height: 60%;
             }
            .card{
                height: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                @media (min-width:1600px) {
                    height: 70%;
                }
                @media (max-width: 550px) {
                    height: 95%;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    height: 100%;
                    padding-bottom: 20px;
                 }
                img{
                    width: 40%;
                    @media (min-width:1600px) {
                        width: 30%;
                    }
                    @media (max-width: 550px) {
                        width: 60%;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        width: 30%;
                     }
                }
                .texto{
                    width: 50%;
                    @media (max-width: 550px) {
                        width: 70%;
                    }
                    p{
                        color: white;
                        text-align: center;
                    }
                }
                .icono{
                    width: 250px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 100px;
                        @media (max-width: 550px) {
                            width: 80px;
                        }
                    }
                }
            }
        }
        .right{
            width: 50%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width: 1024px) {
                width: 100%;
                height: 50%;
            }
            @media (max-width: 550px) {
                height: 40%;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                height: 40%;
             }
            img{
                &:nth-child(1){
                    width: 450px;
                    position: absolute;
                    left: -120px;
                    z-index: 9;
                    @media (min-width:1600px) {
                        width: 600px;
                        left: -150px;
                    }
                    @media (max-width: 1024px) {
                        bottom: -40px;
                        left: 25%;
                        width: 400px;
                    }
                    @media (max-width: 550px) {
                        width: 60%;
                        left: 22%;
                        bottom: -25px;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        width: 300px;
                        left: 0px;
                    }
                }
                &:nth-child(2){
                    width: 90%;
                    height: 75%;
                    object-fit: cover;
                    object-position: left;
                    @media (max-width: 1024px) {
                        width: 100%;
                        height: 100%;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        width: 50%;
                        height: 250px;
                    }
                }
            }
        }
    }
}