.contenedor_principal{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .fondo_video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .img_parallax{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            background-repeat: no-repeat;
            background-size: contain !important;
            @media (max-width: 1024px) {
                background-size: cover !important;
                inset: -50px 0 !important;
            }
        }
    }
    &_titulo{
        position: absolute;
        z-index: 1;
        top: 250px;
        width: 30% !important;
        height: auto !important;
        @media (min-width:1600px) {
            top: 315px;
        }
        @media (max-width: 1024px) {
            width: 70% !important;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            width: 30% !important;
            top: 50px;
        }
    }
}