.contenedor_masterplan{
    width: 100%;
    height: 180vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width:1600px) {
        height: 150vh;
    }
    @media (max-width: 1024px) {
        height: 130vh;
    }
    @media (max-width: 550px) {
        height: 120vh;
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: auto;
    }
    &_titulo{
        width: 40%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        @media (max-width: 550px) {
            height: 15%;
        }
        @media (max-width: 990px) and (orientation: landscape) {
           margin-top: 50px;
        }
        img{
            width: 40%;
            @media (min-width:1600px) {
                width: 30%;
            }
            @media (max-width: 1024px) {
                width: 90%;
            }
            @media (max-width: 550px) {
                width: 95%;
            }
            @media (max-width: 990px) and (orientation: landscape) {
               width: 50%;
            }
        }
    }
    &_plan{
        width: 100%;
        height: 50%;
        position: relative;
        @media (max-width: 1024px) {
            height: 30%;
        }
        .background{
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.801);
            a{
                background-color: #652F6C;
                color: white;
                padding: 10px 20px;
                text-decoration: none;
                cursor: pointer;
            }
        }
        &:hover{
            .background{
                display: flex;
            }
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &_lista{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: space-evenly;
        @media (max-width: 1024px) {
            flex-direction: column-reverse;
            height: 50%;
        }
        @media (max-width: 550px) {
            height: 60%;
            align-items: center;
        }
        @media (max-width: 990px) and (orientation: landscape) {
           flex-direction: row;
           padding-bottom: 50px;
        }
        &_prototipos{
            height: 100%;
            display: flex;
            align-items: center;
            @media (max-width: 1024px) {
                justify-content: center;
            }
            ul{
                height: 100%;
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                @media (max-width: 990px) and (orientation: landscape) {
                   height: 180px;
                }
                li{
                    color: #545454;
                    list-style: none;
                    display: flex;
                    div{
                        background-color: #C15E8C;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 30px;

                    }
                    &:nth-child(2){
                       div{
                            background-color: #F1EC77;
                       }
                    }
                    &:nth-child(3){
                        div{
                            background-color: #767DB1;
                        }
                    }
                    &:nth-child(4){
                        div{
                            background-color: #C45E8D;
                        }
                    }
                    &:nth-child(5){
                        div{
                            background-color: #EB786D;
                        }
                    }
                    &:nth-child(6){
                        div{
                            background-color: #8BC378;
                        }
                    }
                }
            }
        }
        &_amenidades{
            height: 100%;
            display: flex;
            align-items: center;
            @media (max-width: 1024px) {
               justify-content: center;
            }
            @media (max-width: 550px) {
                width: 100%;
            }
            ul{
                height: 70%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: repeat(7, 40px);
                @media (max-width: 1024px) {
                    grid-template-columns: 1fr 1fr;
                }
                @media(max-width:550px){
                    height: auto;
                    text-align: left;
                }
                li{
                    color: #545454;
                    list-style: none;
                    display: flex;
                    p{
                        background-color: #FDB71A;
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        margin-right: 30px;
                        text-align: center;
                        font-size: 15px;
                        line-height: 25px;
                        @media(max-width:550px){
                            margin-right: 15px;
                        }
                    }
                    &:nth-child(1){
                        grid-row: 1/2;
                    }
                    &:nth-child(2){
                        grid-row: 2/3;
                    }
                    &:nth-child(3){
                        grid-row: 3/4;
                    }
                    &:nth-child(4){
                        grid-row: 4/5;
                    }
                    &:nth-child(5){
                        grid-row: 5/6;
                    }
                    &:nth-child(6){
                        grid-row: 6/7;
                    }
                    &:nth-child(7){
                        grid-row: 7/8;
                    }
                    &:last-child{
                        grid-row: 1/2;
                    }
                }
                .first-column{
                    grid-column: 1/2;
                }
                .second_column{
                    grid-column: 2/3;
                }
                .third-column{
                    grid-column: 3/4;
                    @media (max-width: 1024px) {
                        grid-column: 2/3;
                    }
                }
            }
        }
    }
}