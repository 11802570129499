.contenedor_footer{
    width: 100%;
    height: 280px;
    background-color: #FDB71A;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    @media (min-width:1600px) {
        height: 335px;
    }
    .logo_footer{
        width: 200px;
        margin-bottom: 20px;
        @media (max-width: 550px) {
            width: 100px;
        }
    }
    p{
        color: white;
        margin-bottom: 20px;
        @media (max-width: 550px) {
            text-align: center;
            width: 90%;
        }
        a{
            color: white;
            &:hover{
                color: #652F6C;
            }
        }
    }
    div{
        display: flex;
        align-items: center;
        p{
            margin-right: 10px;
            margin-bottom: 0;
        }
        img{
            width: 90px;
        }
    }
}