body,*{
    margin: 0;
    padding: 0;
    box-sizing: content-box;
}
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body{
    font-family: "minion-regular";
    font-size: 16px;
    @media (min-width:1600px) {
        font-size: 18px;
    }
}
$morado: #652F6C;
$amarillo: #FDB71A;

.App{
    position: relative;
}
a{
    cursor: pointer;
}

@font-face {
    font-family: "minion-bold";
    src: url(/5.Tipografias/MINIONPRO-BOLD.OTF);
}
@font-face {
    font-family: "minion-regular";
    src: url(/5.Tipografias/MINIONPRO-REGULAR.OTF);
}
.contenedor_img{
    width: 100%;
    height: 100vh;
    @media (max-width: 550px) {
        height: 70vh;
    }
    .img_parallax{
        height: 100%;
        div{
            background-position: bottom !important;
        }
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.show-enter{
    opacity: 0;
    transform: scale(0.9);
}
.show-enter-active {
    opacity: 0.5;
    transform: translateX(0);
    transition: opacity 0.1s, transform 0.5s;
}
.show-enter-done  {
    opacity: 1;
}
.show-exit {
    opacity: 1;
}
.show-exit-active {
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 0.1s, transform 0.5s;
}
.show-exit-done {
    opacity: 0;
}