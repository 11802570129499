.contenedor_header{
    width: 100%;
    height: 80px;
    display: flex;
    position: fixed;
    justify-content: space-evenly;
    align-items: center;
    z-index: 999;
    @media (min-width:1600px) {
        height: 100px;
    }
    &.back_menu{
        background-color: #652F6C;
        .menu_logo{
            margin-top: 0;
            img{
                width: 50px;
                @media (min-width:1600px) {
                    width: 70px;
                }
            }
        }
    }
    &.ocultar{
        @media (max-width:1024px) {
            display: none;
        }
    }
    .button_descarga{
        width: 300px;
        height: 35px;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
            background-color: #FDB71A;
            a{
                color: white;
            }
        }
        a{
            cursor: pointer;
            color: white;
            font-family: "minion-regular";
            font-size: 20px;
            line-height: 15px;
            padding-top: 5px;
            @media (min-width:1600px) {
                font-size: 23px;
                line-height: 20px;
            }
        }
    }
    .menu_logo{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        img{
            width: 200px;
        }
    }
    .menu_telefono{
        width: 20%;
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a{
            font-family: "minion-regular";
            font-size: 25px;
            color: white;
            text-decoration: none;
            @media (min-width:1600px) {
                font-size: 30px;
            }
        }
        .iconos_social{
            display: flex;
            img{
                margin-left: 15px;
                width: 25px;
                @media (min-width:1600px) {
                    width: auto;
                }
            }
        }
    }
}

header{
    width: 100%;
    height: 130px;
    position: fixed;
    display: none;
    justify-content: space-between;
    z-index: 999;
    @media (max-width: 550px) {
        height: 85px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: 50px;
    }
    &.mostrar{
        @media (max-width: 1024px) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &.back_menu{
        background-color: #652F6C;
        .menu_logo{
            width: 55%;
            margin-top: 0;
            @media (max-width:1024px) {
                width: 60%;
            }
            @media (max-width: 550px) {
                width: 63%;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                width: 55%;
            }
            img{
                width: 50px;
                @media (max-width:1024px) {
                    width: 180px;
                }
                @media (max-width: 550px) {
                    width: 120px;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    width: 70px;
                }
            }
        }
        .btn-toggle {
            span {
                background-color: white;
                &::before, &::after {
                    background-color:white;
                }
            }
        }
    }
    .icon_menu{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 550px) {
           width: 20%;
        }
    }
    .menu_logo{
        width: 60%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 550px) {
            width: 63%;
        }
        @media (max-width: 990px) and (orientation: landscape) {
            width: 55%;
        }
        img{
            width: 180px;
            @media (max-width: 550px) {
                width: 120px;
                margin-top: 10px;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                width: 70px;
            }
        }
    }
    &.menu-open span{
        background-color: transparent !important;
        &::before {
            top: 0 !important;
            transform: rotate(-45deg);
        }
        &::after {
            bottom: 0 !important;
            transform: rotate(45deg);
        }
    }
    &.opened{
        background-color: #FDB71A;
        height: 100vh;
        z-index: 999;
        align-items: flex-start;
        .menu{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 75%;
            @media (max-width:1024px) {
                align-items: center;
            }
            @media (max-width:550px) {
               bottom: 80px;
              
            }
            ul{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                height: 100%;
                @media (min-width:1600px) {
                    justify-content: space-between;
                }
                li{
                    list-style: none;
                   a{
                       cursor: pointer;
                    color: #652F6C;
                    text-decoration: none;
                    font-size: 25px;
                    @media (min-width:1600px) {
                        font-size: 30px;
                    }
                    @media (max-width:550px) {
                        font-size: 25px;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                       font-size: 20px;
                    }
                   }
                   img{
                       width: 150px;
                       margin-top: 30px;
                       @media (min-width:1600px) {
                        
                        }
                   }
                }
            }
            .iconos_social{
                width: 20%;
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                @media (max-width: 990px) and (orientation: landscape) {
                    width: 8%;
                }
            }
        }
        .icon_menu{
            height: 130px;
            @media (max-width: 550px) {
                height: 85px;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                height: 50px;
            }
        }
        .menu_logo{
            height: 130px;
            @media (max-width: 550px) {
                height: 85px;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                height: 50px;
            }
        }
        &.back_menu .icon_logo{
            height: 80px !important;
        }
    }
    .menu{
        display: none;
        .descarga{
            text-align: center;
            color: white;
            font-size: 20px;
            @media (max-width: 990px) and (orientation: landscape) {
                font-size: 18px;
            }
        }
    }
    .btn-toggle {
        width: 50px;
        height: 50px;
        margin-right: 0px !important;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 3.8px;
            width: 60%;
            background-color: #652F6C;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color:#652F6C;
                position: absolute;
                left: 0;
                transition: width .5s;
            }
    
            &::before {
                top: -8px;
            }
            &::after {
                bottom: -7px;
            }
            &::before, &::after {
                transition: all .3s;
            }
        }
    }
}