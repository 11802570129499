.contenedor_grid{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    background-color: #652F6C;
    @media (max-width:1024px) {
        grid-template-columns: 1fr;
        grid-template-rows: 50% 50%;
    }
}
.contenedor_concepto{
    width: 100%;
    height: 100vh;
    @media (max-width: 550px) {
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: auto;
    }
    .contenedor_grid{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 100vh;
        @media (max-width:1024px) {
            grid-template-columns: 1fr;
            grid-template-rows: 50% 50%;
        }
        @media (max-width:550px) {
            grid-template-rows: 40% 60%;
        }
    }
    .right{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            @media (max-width:1024px) {
                grid-row: 2/3;
            }
        .card{
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            @media (max-width: 550px) {
                height: 95%;
            }
            @media (max-width: 990px) and (orientation: landscape) {
                height: 95%;
            }
            img{
                width: 30%;
                @media (max-width: 550px) {
                    width: 60%;
                }
            }
            .texto{
                width: 60%;
                @media (max-width: 550px) {
                    width: 80%;
                }
                p{
                    color: white;
                    text-align: center;
                }
            }
            .button_descarga{
                width: 250px;
                height: 35px;
                border: 1px solid white;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @media (max-width: 550px) {
                    width: 150px;
                }
                &:hover{
                    background-color: #FDB71A;
                    a{
                        color: white;
                    }
                }
                a{
                    text-decoration: none;
                    cursor: pointer;
                    color: white;
                    font-family: "minion-regular";
                    font-size: 23px;
                    line-height: 20px;
                    padding-top: 5px;
                    @media (max-width: 550px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .left{
        width: 100%;
        height: 100%;
        position: relative;
        @media (max-width:1024px) {
            grid-row: 1/2;
        }
        .icon_play{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100px;
            cursor: pointer;
        }
        .fondo_video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
