.contenedor_descripcion{
    width: 100%;
    height: 100vh;
    @media (max-width: 550px) {
        min-height: 1020px;
    }
    @media (max-width: 990px) and (orientation: landscape) {
        height: auto;
    }
    &_card{
        width: 100%;
        height: 100%;
        display: flex;
        background-image: url(../../assets/img_concepto_2_la_serena.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media (max-width:1024px) {
            flex-direction: column-reverse;
        }
        .left{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            @media (max-width:1024px) {
                width: 100%;
                height: 50%;
            }
            @media (max-width:550px) {
               height: 60%;
            }
            @media (max-width: 990px) and (orientation: landscape) {
               padding: 20px 0;
            }
            .card{
                height: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                @media (min-width:1600px) {
                    height: 70%;
                }
                @media (max-width: 550px) {
                    height: 95%;
                }
                @media (max-width: 990px) and (orientation: landscape) {
                    height: 95%;
                }
                img{
                    width: 40%;
                    @media (min-width:1600px) {
                        width: 30%;
                    }
                    @media (max-width: 550px) {
                        width: 60%;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        width: 30%;
                    }
                }
                .texto{
                    width: 50%;
                    @media (max-width: 550px) {
                        width: 70%;
                    }
                    p{
                        color: white;
                        text-align: center;
                    }
                }
                .button_descarga{
                    width: 250px;
                    height: 35px;
                    border: 1px solid white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    @media (max-width: 550px) {
                        width: 150px;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        margin-top: 20px;
                    }
                    &:hover{
                        background-color: #FDB71A;
                        a{
                            color: white;
                        }
                    }
                    a{
                        text-decoration: none;
                        cursor: pointer;
                        color: white;
                        font-family: "minion-regular";
                        font-size: 23px;
                        line-height: 20px;
                        padding-top: 5px;
                        @media (max-width: 550px) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        .right{
            width: 50%;
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media (max-width:1024px) {
                width: 100%;
                height: 50%;
            }
            @media (max-width:550px) {
                height: 4   0%;
             }
            img{
                &:nth-child(1){
                    width: 450px;
                    position: absolute;
                    left: -25px;
                    z-index: 9;
                    @media (min-width:1600px) {
                        width: 600px;
                    }
                    @media (max-width:550px) {
                        width: 80%;
                        position: absolute;
                        left: -58px;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        left: 0px;
                        width: 300px;
                    }
                }
                &:nth-child(2){
                    height: 80%;
                    @media (max-width:1024px) {
                        width: 50%;
                        height: 80%;
                        object-fit: cover;
                    }
                    @media (max-width: 990px) and (orientation: landscape) {
                        height: 350px;
                        width: 350px;
                        object-position: center;
                    }
                }
            }
        }
    }
}