.contenedor_modal_prototipos{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.548);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .closed{
        color: white;
        font-size: 30px;
        position: absolute;
        right: 5%;
        top: 5%;
        cursor: pointer;
    }
    .modal{
        width: 80%;
        height: 90%;
        .swiper{
            width: auto;
            height: 100%;
            max-width: 100%;
            .swiper-pagination-fraction {
                bottom: 3% !important;
                font-size: 20px !important;
                left: 40%;
                width: auto !important;
                @media (max-width: 550px) {
                    left: 20% !important;
                }
            }
            .swiper-button-prev{
                right: auto!important;
                left: 48% !important;
                top: auto !important;
                bottom: 2% !important;
                @media (max-width: 550px) {
                    left: 65% !important;
                }
                &::after{
                    font-size: 20px !important;
                    color: #652F6C;
            
                }
            }
            .swiper-button-next{
                top: auto !important;
                bottom: 2% !important;
                right: auto!important;
                left: 50% !important;
                width: 30px;
                @media (max-width: 550px) {
                    left: 75% !important;
                }
                &::after{
                    font-size: 20px !important;
                    color: #652F6C;
                }
            }
            .carousel__slider{
                width: 100%;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}